import { MouseEvent, useEffect, useRef, useState } from 'react'
import { CardCarouselProps } from './types'

export const cardCarouselIO = ({ spacing = 0 }: CardCarouselProps) => {
    const carousel = useRef<HTMLDivElement>(null)
    const [scroll, setScroll] = useState(0)

    const totalWidth = carousel.current?.scrollWidth ?? 0
    const visibleWidth = carousel.current?.offsetWidth ?? 0
    const maxScroll = totalWidth - visibleWidth

    useEffect(() => {
        if (carousel.current) setScroll(carousel.current.scrollLeft)
    }, [carousel.current])

    useEffect(() => {
        if (carousel.current) carousel.current.scrollLeft = scroll
    }, [scroll])

    const scrollShowcase = (direction: 'left' | 'right') => {
        const changeDirection = direction === 'left' ? -1 : 1
        let target = (visibleWidth + spacing) * changeDirection + scroll

        if (target < 0) target = 0
        if (target > maxScroll) target = maxScroll

        setScroll(target)
    }

    const handleScrollLeft = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        scrollShowcase('left')
    }

    const handleScrollRight = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        scrollShowcase('right')
    }

    const isFirstStep = () => {
        return scroll <= 0
    }

    const isLastStep = () => {
        if (!carousel.current) return false
        return scroll >= maxScroll
    }

    return {
        carousel,
        handleScrollLeft,
        handleScrollRight,
        isFirstStep,
        isLastStep
    }
}

export type CardCarouselIO = ReturnType<typeof cardCarouselIO>
