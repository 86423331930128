import { SwipeableButton } from '@components/SwipeableButton'
import { IOProps } from 'react-compose-io'
import { CardCarouselIO } from './io'
import * as Styled from './styles'
import { CardCarouselProps } from './types'

export const CardCarouselView = ({
    spacing = 0,
    children,
    className,
    elementsPerPage,
    fixedLeftElement,
    title,
    buttonPosition = 'bottom',
    showButtons = true,
    buttonSize = 50,
    buttonId,
    justifyProductsToCenter,
    _io
}: IOProps<CardCarouselIO, CardCarouselProps>): JSX.Element => {
    return (
        <Styled.Container
            direction={buttonPosition === 'between' ? 'row' : 'column'}
        >
            {buttonPosition === 'bottom' && (
                <>
                    {fixedLeftElement}
                    {title}
                    <Styled.Slider
                        className={className}
                        ref={_io.carousel}
                        spacing={spacing}
                    >
                        <Styled.ItemWidthController
                            spacing={spacing}
                            elementsPerPage={elementsPerPage}
                        >
                            {children}
                        </Styled.ItemWidthController>
                        <Styled.ScrollHider />
                    </Styled.Slider>
                    {showButtons && (
                        <Styled.ButtonContainer>
                            <SwipeableButton
                                onClick={_io.handleScrollLeft}
                                direction="left"
                                disabled={_io.isFirstStep()}
                                size={buttonSize}
                            />
                            <SwipeableButton
                                onClick={_io.handleScrollRight}
                                direction="right"
                                disabled={_io.isLastStep()}
                                size={buttonSize}
                            />
                        </Styled.ButtonContainer>
                    )}
                </>
            )}
            {buttonPosition === 'between' && (
                <>
                    <Styled.ArrowButtonContainer justifyContent="center">
                        <Styled.LeftArrow
                            onClick={_io.handleScrollLeft}
                            disabled={_io.isFirstStep()}
                            size={buttonSize}
                        />
                    </Styled.ArrowButtonContainer>
                    {fixedLeftElement}
                    <Styled.Slider className={className} ref={_io.carousel}>
                        <Styled.ItemWidthController
                            spacing={spacing}
                            elementsPerPage={elementsPerPage}
                        >
                            {children}
                        </Styled.ItemWidthController>
                    </Styled.Slider>
                    <Styled.ArrowButtonContainer>
                        <Styled.RightArrow
                            onClick={_io.handleScrollRight}
                            disabled={_io.isLastStep()}
                            direction="right"
                            size={buttonSize}
                        />
                    </Styled.ArrowButtonContainer>
                </>
            )}
            {buttonPosition === 'top' && (
                <div id={buttonId}>
                    <Styled.TitleContainer>
                        {title}
                        {showButtons && (
                            <Styled.TopButtonContainer>
                                <Styled.LeftArrow
                                    onClick={_io.handleScrollLeft}
                                    disabled={_io.isFirstStep()}
                                    size={40}
                                />
                                <Styled.RightArrow
                                    onClick={_io.handleScrollRight}
                                    disabled={_io.isLastStep()}
                                    direction="right"
                                    size={40}
                                />
                            </Styled.TopButtonContainer>
                        )}
                    </Styled.TitleContainer>

                    <Styled.TopButtonShowCaseContainer>
                        {fixedLeftElement}
                        <Styled.Slider className={className} ref={_io.carousel}>
                            <Styled.ItemWidthController
                                spacing={spacing}
                                elementsPerPage={elementsPerPage}
                                buttonPosition={buttonPosition}
                                justifyProductsToCenter={
                                    justifyProductsToCenter
                                }
                            >
                                {children}
                            </Styled.ItemWidthController>
                        </Styled.Slider>
                    </Styled.TopButtonShowCaseContainer>
                </div>
            )}
        </Styled.Container>
    )
}
