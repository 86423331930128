import { Stack } from '@components/Stack'
import { SwipeableButton } from '@components/SwipeableButton'
import styled from 'styled-components'

export const Slider = styled(Stack).attrs({
    fullWidth: true,
    direction: 'row'
})`
    overflow-x: auto;
    scroll-behavior: smooth;
    object-fit: cover;
    -ms-overflow-style: none;
    scrollbar-width: none;

    ::-webkit-scrollbar {
        display: none;
    }

    touch-action: none;
`

export const ButtonContainer = styled(Stack).attrs({
    alignItems: 'center',
    justifyContent: 'center',
    direction: 'row',
    fullWidth: true,
    spacing: 32
})``

export const Container = styled(Stack).attrs({
    fullWidth: true,
    justifyContent: 'center',
    alignItems: 'stretch'
})``

export const LeftArrow = styled(SwipeableButton)``

export const RightArrow = styled(SwipeableButton)``

export const ScrollHider = styled.div`
    position: absolute;
    height: 10px;
    width: 100%;
    bottom: 0;
`

export type ItemWidthControllerProps = {
    spacing: number
    elementsPerPage: number | undefined
    buttonPosition?: string
    justifyProductsToCenter?: boolean
}

const getContainerCalc = ({
    spacing,
    elementsPerPage
}: ItemWidthControllerProps) => {
    if (!elementsPerPage) return 0

    const spacingSize = spacing * (elementsPerPage - 1)
    return `calc((100% - ${spacingSize}px) / ${elementsPerPage})`
}

export const ItemWidthController = styled.div<ItemWidthControllerProps>`
    width: 100%;
    display: flex;
    width: ${({ theme }) => !theme.isDesktop && 330}px;
    margin-bottom: ${({ theme }) => !theme.isDesktop && 30}px;
    justify-content: ${({ justifyProductsToCenter }) =>
        justifyProductsToCenter && 'center'};

    > * {
        flex: 0 0 ${getContainerCalc};
        margin-right: ${({ spacing }) => spacing}px;
    }

    > *:last-child {
        margin-right: 0;
    }
`

export const TopButtonContainer = styled(Stack).attrs({
    justifyContent: 'flex-end',
    direction: 'row',
    spacing: 16,
    fullWidth: true
})`
    margin: 0 0 auto 0;
`

export const TitleContainer = styled(Stack).attrs({
    justifyContent: 'space-between',
    direction: 'row',
    fullWidth: true
})`
    margin-bottom: 32px;
`

export const ArrowButtonContainer = styled(Stack).attrs({
    justifyContent: 'center'
})`
    margin: 8px;
`

export const TopButtonShowCaseContainer = styled(Stack).attrs({
    justifyContent: 'center',
    alignItems: 'stretch',
    direction: 'row'
})``
